import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import feedbacks from './FeedbackData';

const FeedbackPage = () => {
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const calculateAverageRating = () => {
    const totalRatings = feedbacks.reduce((sum, feedback) => sum + feedback.rating, 0);
    return (totalRatings / feedbacks.length).toFixed(1); // One decimal place
  };

  const  averageRating = calculateAverageRating();

  // Divide feedbacks into chunks of 10
  const chunkedFeedbacks = [];
  for (let i = 0; i < feedbacks.length; i += 6) {
    chunkedFeedbacks.push(feedbacks.slice(i, i + 6));
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Feedback</h2>
      <h2 style={styles.title} > Average Rating: {averageRating} / 5 ⭐</h2>
      <Slider {...sliderSettings} style={styles.slider}>
        {chunkedFeedbacks.map((chunk, index) => (
          <div key={index} style={styles.slide}>
            <div style={styles.grid}>
              {chunk.map((item, idx) => (
                <div key={idx} style={styles.feedbackCard}>
                  <h3 style={styles.name}>{item.name}</h3>
                  <p style={styles.jobTitle}>{item.jobTitle}</p>
                  <p style={styles.feedbackText}>"{item.feedback}"</p>
                  <p style={styles.rating}>
                    {"⭐".repeat(item.rating)}{" "}
                    <span style={styles.ratingText}>({item.rating}/5)</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#e8f5e9', // Light green background
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    paddingBottom:'4rem',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#2e7d32', // Dark green for the title
  },
  slider: {
    width: '100%',
  },
  slide: {
    padding: '10px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // 5 columns
    gridGap: '30px',
  },
  feedbackCard: {
    border: '5px solid #a5d6a7', // Soft green border
    borderRadius: '8px',
    padding: '15px',
    backgroundColor: '#ffffff', // White background for contrast
    textAlign: 'center',
  },
  name: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#2e7d32', // Green for the customer name
  },
  jobTitle: {
    fontSize: '14px',
    color: '#388e3c', // Slightly lighter green for job title
    marginBottom: '10px',
  },
  feedbackText: {
    fontStyle: 'italic',
    marginBottom: '10px',
    color: '#555', // Subtle gray for feedback text
  },
  rating: {
    fontSize: '20px',
    color: '#f9a825', // Golden color for stars
  },
  ratingText: {
    color: '#333',
  },
};

export default FeedbackPage;

import React, { useState } from 'react';
import './Album.css';
import PatioPhotos from './photostorage/PatioPhotos';
import LazyLoad from 'react-lazyload';

// const photos = [
//   { src: 'images/roofing.jpg', alt: 'Cute Kitten 1' },
//   { src: 'images/block-paving.jpg', alt: 'Cute Kitten 2' },
//   { src: 'images/patio.jpg', alt: 'Cute Kitten 3' },
//   { src: 'images/fencing.jpg', alt: 'Cute Kitten 4' },
//   { src: 'images/brick-work.jpg', alt: 'Cute Kitten 5' },
//   { src: 'images/shingle.jpg', alt: 'Cute Kitten 6' },
// ];

const PatioAlbum = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const openLightbox = (index) => {
    setSelectedIndex(index);
  };

  const closeLightbox = () => {
    setSelectedIndex(null);
  };

  const showNextPhoto = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % PatioPhotos.length);
  };

  const showPreviousPhoto = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + PatioPhotos.length) % PatioPhotos.length);
  };

  return (
    <div className="photo-album">
      <div className='albumtitle'>
        <h2 >Patio </h2>
        <p>Below are the patio projects that have been carried out and completed.</p>
      </div>
      
      <div className="gallery-grid">
        {PatioPhotos.map((photo, index) => (
          <LazyLoad height={200} offset={100}>
          <img
          // srcSet="image-500w.jpg 500w, image-1000w.jpg 1000w, image-2000w.jpg 2000w" 
          // sizes="(max-width: 600px) 500px, (max-width: 1000px) 1000px, 2000px" 
            key={index}
            src={photo.src}
            alt={photo.alt}
            onClick={() => openLightbox(index)}
            className="gallery-photo"
          />
          </LazyLoad>
        ))}
      </div>

      {selectedIndex !== null && (
        <div className="lightbox" onClick={closeLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
          <LazyLoad height={200} offset={100}>
          
            <img
              src={PatioPhotos[selectedIndex].src}
              alt={PatioPhotos[selectedIndex].alt}
            />
            </LazyLoad>
            <button className="close-btn" onClick={closeLightbox}>
              Close
            </button>
            <button className="arrow left-arrow" onClick={showPreviousPhoto}>
              &#9664;
            </button>
            <button className="arrow right-arrow" onClick={showNextPhoto}>
              &#9654;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatioAlbum;

import '../../src/App.css';
import Footer from '../components/Footer';
import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { Suspense } from 'react'; 
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';


// Sample data for services
const services = [
  {
    path: 'gallery/roofing',
    name: 'Roofing',
    image: '/images/roofing/R95.webp',
    description: '',
  },
  {
    path: 'gallery/driveways',
    name: 'Driveways',
    image: '/images/driveways/D1.webp',
    description: '',
  },
  {
    path: 'gallery/patio',
    name: 'Patios',
    image: '/images/patio/P1.webp',
    description: '',
  },
  {
    path: 'gallery/brickwork',
    name: 'Brickwork',
    image: '/images/brickwork/BR50.webp',
    description: '',
  },
  {
    path: 'gallery/victoriantiles',
    name: 'Victorian Tiles',
    image: '/images/victorian/V4.webp',
    description: '',
  },
  {
    path: 'gallery/bathroom',
    name: 'Bathroom',
    image: '/images/bathroom/B5.webp',
    description: '',
  },
  {
    path: 'gallery/fencing',
    name: 'Fencing',
    image: '/images/fencing/F8.webp',
    description: '',
  },
  // Add more services as needed
];

const ConstructionServices = () => {
  return (
    <>
    <div className='background'>


    
    <div className="services-list">
    <div className='introservices'>
        <h1>Services</h1>
        <p>Here are the services that we provide to our clients</p>
      </div>
      {services.map((service) => (
        <Link  to={`https://aldconstructionlandscapingltd.co.uk/${service.path}`} className="service-item-link">
          <div className="service-item">
          <LazyLoad height={200} offset={100}>
          <Suspense fallback={<div>Loading...</div>}>
            <img src={service.image} alt={service.name} className="service-image" /></Suspense></LazyLoad>
            <div className="service-content">
              <h3 className="service-title">{service.name}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
    </div>
  <Footer/>
    </>
  );
};

export default ConstructionServices;

import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      
        <h1 className='titleservice'>Some of our Services</h1>

  
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/roofing/R95.webp'
              text='Roofing'
              
              path='/gallery/roofing'
            />
            <CardItem
              src='images/driveways/D5.webp'
              text='Blockpaving'
              
              path='/gallery/driveways'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/patio/P7.webp'
              text='Patio & Paths'
              
              path='/gallery/patio'
            />
            <CardItem
              src='images/fencing/F9.webp'
              text='Fencing & Gates'
              
              path='/gallery/fencing'
            />
            <CardItem
              src='images/brickwork/BR3.webp'
              text='Brickwork'
              
              path='/gallery/brickwork'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

const FencingPhotos =[
    { src: '/images/fencing/F1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F2.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F13.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F14.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F15.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F16.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F20.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F22.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F23.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F24.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F25.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F26.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F27.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F28.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/fencing/F30.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F34.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F35.webp', alt: 'Cute Kitten 1' },
    { src: '/images/fencing/F36.webp', alt: 'Cute Kitten 1' },
];
export default FencingPhotos;
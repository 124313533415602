import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { Suspense } from 'react'; 

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>

          <figure className='cards__item__pic-wrap' data-category={props.label}>
          <LazyLoad height={200} offset={100}>
          <Suspense fallback={<div>Loading...</div>}>
            <img
              className='cards__item__img'
              alt='Travel'
              src={props.src}
            /></Suspense></LazyLoad>
          
          </figure>
          <div className='cards__item__info'>
          
            <h5 className='cards__item__text'>{props.text}</h5>
          
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;

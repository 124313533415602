import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import RoofingAlbum from '../components/photoalbums/RoofingAlbum';
export default function Roofing() {
  return (
<>
 <RoofingAlbum/>
  <Footer/> 
</>
);
}
import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';
import icon from '../logo4.png';


function Footer() {
  return (
    <div className='footer-container'>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Our Services</h2>
            <Link to='/gallery/driveways'>Blockpaving</Link>
            <Link to='/gallery/roofing'>Roofing</Link>
            <Link to='/gallery/patio'>Patio</Link>
            <Link to='/services'>Many More</Link>
            
          </div>
          <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.instagram.com/aldconstructionltd/'>Instagram</Link>
            <Link to='https://www.checkatrade.com/trades/aldconstructionandlandscaping'>Checkatrade</Link>
            <Link to='https://www.trustatrader.com/traders/ald-construction-and-landscaping-ltd-blockpaving-driveways-haringey'>Trustatrade</Link>
          </div>
        </div>
          <div class='footer-link-items'>
            <h2>Need Help</h2>
            <Link to='/contact-us'>Just Contact Us</Link>
          </div>
        </div>
        
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
               
              <img src={icon} alt='travel'class='icon'/>
              <h1 className='footername'>ALD</h1>
              <h3 className='footerlast'>Construction & Landscaping</h3>
              </Link>
          </div>
          <small class='website-rights'>ALD Construction and Landscaping LTD © 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import BrickworkAlbum from '../components/photoalbums/BrickworkAlbum';
export default function Brickwork() {
  return (
<>
 <BrickworkAlbum/>
  <Footer/> 

</>
)};
const BrickworkPhotos =[

    
    { src: '/images/brickwork/BR2.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR16.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR20.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR21.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR22.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR23.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR24.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR25.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/brickwork/BR29.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR37.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR38.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR39.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR40.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR41.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR42.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR43.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR44.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR45.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/brickwork/BR47.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR48.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR49.webp', alt: 'Cute Kitten 1' },
    { src: '/images/brickwork/BR50.webp', alt: 'Cute Kitten 1' },
   
  ];
  export default BrickworkPhotos;

const PatioPhotos = [
    { src: '/images/patio/P1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P2.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P13.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P14.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P15.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/patio/P17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P20.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P21.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P22.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P23.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P24.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P25.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P26.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P27.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P28.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P29.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P30.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P32.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P33.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P34.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P35.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P36.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P37.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P38.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P39.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P40.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P41.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/patio/P43.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/patio/P46.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P47.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P48.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P49.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/patio/P51.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P52.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P53.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P54.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P55.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P56.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P57.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P58.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/patio/P60.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P61.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P62.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P63.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P64.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P65.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P66.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P67.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P68.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P69.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P70.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P71.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P72.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P73.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P74.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P75.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P76.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P77.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P78.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P79.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P80.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P81.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P82.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P83.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P84.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P85.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/patio/P87.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P88.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P89.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P90.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P91.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P92.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P93.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P94.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P95.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P96.webp', alt: 'Cute Kitten 1' },
  
    { src: '/images/patio/P98.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P99.webp', alt: 'Cute Kitten 1' },
    { src: '/images/patio/P100.webp', alt: 'Cute Kitten 1' },
   
    
  ];
  export default PatioPhotos;
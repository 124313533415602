import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import VictorianAlbum from '../components/photoalbums/VictorianAlbum';
export default function Victorian() {
  return (
<>
 <VictorianAlbum/>
  <Footer/> 
</>
);
}
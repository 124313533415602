import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import checkatrade from  '../checkatrade.webp'
import trustatrade from '../trustatrade-logo.png';
import { Link } from 'react-router-dom';
import FeedbackPage from '../components/FeedbackPage';
export default function ContactUs() {
  return (
<>
  <h1 className='contact-us'>Contact Us for a Quote  
   
  
  </h1>
 
      <div className='contactusinfo'>
        <div className='details'>
          <h3>
            Email: info@aldconstruction.co.uk
          </h3> 
          <h3>Phone Number: 07984440202</h3>
        </div>
      </div>

      <FeedbackPage/>
      <h1 className='checktext'>
        Check Us Out On: 
      </h1>
      <div className='checktrust'>
      <Link to='https://www.checkatrade.com/trades/aldconstructionandlandscaping'>
      <img className='checkatrade'src={checkatrade} alt='travel'/>
      </Link>
      
      <Link to='https://www.trustatrader.com/traders/ald-construction-and-landscaping-ltd-blockpaving-driveways-haringey'>
      <img className='trustatrade'src={trustatrade} alt='travel'/>
      </Link>
        
        </div>
      
     
  <Footer/> 
</>
)
}

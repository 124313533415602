import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Button } from './Button';



 

const CustomerFeedback = () => {
  //  const navigate = useNavigate();

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll
    });

    // Navigate to the desired page
    // navigate('/feedback'); // Replace '/feedback' with the target route
  };
  
  const feedbacks = [
    {
      name: "Deirdre",
      jobTitle: "Roof Renewal",
      feedback: "We had a leak in the house from the roof! We called the company and they started straight away, worked in all weathers, scaffold was up the next day and work started after that! The workers were great and the job completed to a very high standard! Talking us through every detail ! Would recommend them every time !",
      rating: 5,
    },
    {
      name: "Elliot",
      jobTitle: "New Driveway, garden excavation and new turf laid",
      feedback: "They were professional, efficient, and paid great attention to detail from start to finish. The driveway looks fantastic, with clean lines and a perfect finish, and the garden transformation is just as impressive. They kept the area tidy, communicated clearly throughout the process, and completed the work on time. I highly recommend them for anyone looking for quality work and a smooth experience!",
      rating: 5,
    },
    {
      name: "John",
      jobTitle: "Driveway and Pathway Paving",
      feedback: "Agron and his team gave a very competitive quote, were very reliable, turned up when they said they would and worked consistently throughout the week until the job was finished. Other companies quoted us 2-3 weeks for the finished job, whereas Agron completed in a week and did a very good job, including carrying out extra work which we asked for. In all he took away the old side garden and tree stump, built a new paved driveway and paved walkway down the side alley to garden. Took down old front garden wall and rebuilt new wall. Paved the front garden under the bay window. Great job.",
      rating: 4.5,
    },
    {
      name: "Maia",
      jobTitle: "Decking & Garden Fence",
      feedback: "Agron and team were great, were very efficient. Would recommend.",
      rating: 4.5,
    },
    {
      name: "Amelia",
      jobTitle: "New Concrete Base, Painting & Decorating",
      feedback: "Very happy with the work carried, they communicated very well with me, I would definitely recommend them ",
      rating: 5,
    },
    {
      name: "Tobutt",
      jobTitle: "Garden Landscaping, Turf, Resin Bond For Drive",
      feedback: "Good service, friendly and reliable",
      rating: 4.5,
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>What Our Customers Say</h2>
      <Slider {...sliderSettings} style={styles.slider}>
        {feedbacks.map((item, index) => (
          <div key={index} style={styles.feedbackCard}>
            <h3 style={styles.name}>{item.name}</h3>
            <p style={styles.jobTitle}>{item.jobTitle}</p>
            <p style={styles.feedbackText}>"{item.feedback}"</p>
            <p style={styles.rating}>
              {"⭐".repeat(item.rating)}{" "}
              <span style={styles.ratingText}>({item.rating}/5)</span>
            </p>

          </div>
        ))}
      </Slider>
      <Button 
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleClick}
        >
          View all Feedback
        </Button>
    </div>
  );
};

const styles = {
  feedbutton:{
    padding:'2rem',
  },
  container: {
    padding: '50px',
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#e8f5e9', // Light green background
    
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#2e7d32', // Dark green for the title
  },
  slider: {
    width: '100%',
  },
  feedbackCard: {
    border: '1px solid #a5d6a7', // Soft green border
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff', // White background for contrast
    textAlign: 'left',
  },
  name: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#2e7d32', // Green for the customer name
  },
  jobTitle: {
    fontSize: '16px',
    color: '#388e3c', // Slightly lighter green for job title
    marginBottom: '10px',
  },
  feedbackText: {
    fontStyle: 'italic',
    marginBottom: '50px',
    color: '#555', // Subtle gray for feedback text
  },
  rating: {
    fontSize: '16px',
    color: '#f9a825', // Golden color for stars
    marginBottom: '50px',
  },
  ratingText: {
    color: '#333',
  },
};

export default CustomerFeedback;


// const CustomerFeedback = () => {
//     const feedbacks = [
//       {
//         name: "Deirdre",
//         jobTitle: "Roof Renewal",
//         feedback: "We had a leak in the house from the roof! We called the company and they started straight away, worked in all weathers, scaffold was up the next day and work started after that! The workers were great and the job completed to a very high standard! Talking us through every detail ! Would recommend them every time !",
//         rating: 5,
//       },
//       {
//         name: "Elliot",
//         jobTitle: "New Driveway, garden excavation and new turf laid",
//         feedback: "hey were professional, efficient, and paid great attention to detail from start to finish. The driveway looks fantastic, with clean lines and a perfect finish, and the garden transformation is just as impressive. They kept the area tidy, communicated clearly throughout the process, and completed the work on time. I highly recommend them for anyone looking for quality work and a smooth experience!",
//         rating: 5,
//       },
//       {
//         name: "John",
//         jobTitle: "Driveway and Pathway Paving",
//         feedback: "Agron and his team gave a very competitive quote, were very reliable, turned up when they said they would and worked consistently throughout the week until the job was finished. Other companies quoted us 2-3 weeks for the finished job, whereas Agron completed in a week and did a very good job, including carrying out extra work which we asked for. In all he took away the old side garden and tree stump, built a new paved driveway and paved walkway down the side alley to garden. Took down old front garden wall and rebuilt new wall. Paved the front garden under the bay window. Great job.",
//         rating: 4.5,
//       },
//       {
//         name: "Maia",
//         jobTitle: "Decking & Garden Fence",
//         feedback: "Agron and team were great, were very efficient. Would recommend.",
//         rating: 4.5,
//       },
//       {
//         name: "Amelia",
//         jobTitle: "New Concrete Base, Painting & Decorating",
//         feedback: "Very happy with the work carried, they communicated very well with me, I would definitely recommend them ",
//         rating: 5,
//       },
//       {
//         name: "Tobutt",
//         jobTitle: "Garden Landscaping, Turf, Resin Bond For Drive",
//         feedback: "Good service, friendly and reliable",
//         rating: 4.5,
//       },
//     ];
  
//     return (
//       <div style={styles.container}>
//         <h2 style={styles.title}>What Our Customers Say</h2>
//         <div style={styles.feedbackList}>
//           {feedbacks.map((item, index) => (
//             <div key={index} style={styles.feedbackCard}>
//               <h3 style={styles.name}>{item.name}</h3>
//               <p style={styles.jobTitle}>{item.jobTitle}</p>
//               <p style={styles.feedbackText}>"{item.feedback}"</p>
//               <p style={styles.rating}>
//                 {"⭐".repeat(item.rating)}{" "}
//                 <span style={styles.ratingText}>({item.rating}/5)</span>
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   };
  
//   const styles = {
//     container: {
//       padding: '20px',
//       maxWidth: '100%',
//       margin: '0 auto',
//       textAlign: 'center',
//       fontFamily: 'Arial, sans-serif',
//       backgroundColor: '#e8f5e9', // Light green background
      
//     },
//     title: {
//       fontSize: '24px',
//       marginBottom: '20px',
//       color: '#2e7d32', // Dark green for the title
//     },
//     feedbackList: {
//       display: 'flex',
//       flexWrap: 'wrap',
//       justifyContent: 'space-between',
//       gap: '15px',
//     },
//     feedbackCard: {
//       border: '1px solid #a5d6a7', // Soft green border
//       borderRadius: '8px',
//       padding: '15px',
//       width: 'calc(50% - 10px)',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       backgroundColor: '#ffffff', // White background for contrast
//     },
//     name: {
//       fontSize: '18px',
//       fontWeight: 'bold',
//       marginBottom: '5px',
//       color: '#2e7d32', // Green for the customer name
//     },
//     jobTitle: {
//       fontSize: '16px',
//       color: '#388e3c', // Slightly lighter green for job title
//       marginBottom: '10px',
//     },
//     feedbackText: {
//       fontStyle: 'italic',
//       marginBottom: '10px',
//       color: '#555', // Subtle gray for feedback text
//     },
//     rating: {
//       fontSize: '16px',
//       color: '#f9a825', // Golden color for stars
//     },
//     ratingText: {
//       color: '#333',
//     },
//   };
  
//   export default CustomerFeedback;
  
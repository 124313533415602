import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import PatioAlbum from '../components/photoalbums/PatioAlbum';
export default function Patio() {
  return (
<>
 <PatioAlbum/>
  <Footer/> 

</>

)};
import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import Roofing from './pages/Roofing';
import Driveways from './pages/Driveways';
import Patio from './pages/Patio';
import Fencing from './pages/Fencing';
import Brickwork from './pages/Brickwork';
import Bathroom from './pages/Bathroom';
import Victorian from './pages/Victorian';
function App() {

  return (
    <>
    
      <Router>

        <Navbar />
          
        <Routes>
          <Route path='/'   element={<Home/>}/>
          <Route path='/services' element={<Services/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/gallery' element={<Gallery/>} />
          <Route path='/gallery/roofing' element={<Roofing/>} />
          <Route path='/gallery/driveways' element={<Driveways/>} />
          <Route path='/gallery/patio' element={<Patio/>} />
          <Route path='/gallery/fencing' element={<Fencing/>} />
          <Route path='/gallery/brickwork' element={<Brickwork/>} />
        
          <Route path='/gallery/bathroom' element={<Bathroom/>} />
          <Route path='/gallery/victoriantiles' element={<Victorian/>}/>
          
        </Routes>

      </Router>
    </>
  );
}

export default App;


import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
// import PhotoAlbum from '../components/PhotoAlbum';
import GalleryCards from '../components/GalleryCards';
export default function Gallery() {
  return (
  <>
  <GalleryCards/>
  {/* <PhotoAlbum/> */}
  <Footer/>
  </>
  

  )
  ;
}

import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import DrivewayAlbum from '../components/photoalbums/DrivewayAlbum';
export default function Driveways() {
  return (
<>
 <DrivewayAlbum/>
  <Footer/> 

</>
);
}
import React from 'react';
import '../../src/App.css';
import Footer from '../components/Footer';
import FencingAlbum from '../components/photoalbums/FencingAlbum';
export default function Fencing() {
  return (
<>
 <FencingAlbum/>
  <Footer/> 

</>
)};
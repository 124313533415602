import React from "react";
import './HomePageInfo.css';
import {Link} from 'react-router-dom';
import trustatrade from '../trustatrade-logo.png';
import checkatrade from '../checkatrade.webp';

function HomePageInfo(){
    return(
        <div className='homepageinfo'>
    <p>
      At ALD Construction & Landscaping LTD, we bring visions to life with precision, quality, 
      and expertise. With 20 years of experience in residential, commercial, and industrial construction, we specialize in roofing, 
      fencing, structural remodeling, and comprehensive building solutions. Our team is committed to delivering projects on time and within budget, 
      ensuring safety, durability, and exceptional craftsmanship in every job.

      Explore our wide range of services, discover our portfolio of completed projects, and see how we can help turn your ideas into reality. 
      Trust ALD to build the spaces where life happens, with integrity and excellence as our foundation.
    </p>
    <h1 className='checktext1'>
        Check Us Out On: 
      </h1>
      <div className='checktrust1'>
      <Link to='https://www.checkatrade.com/trades/aldconstructionandlandscaping'>
      <img className='checkatrade'src={checkatrade} alt='travel'/>
      </Link>
      
      <Link to='https://www.trustatrader.com/traders/ald-construction-and-landscaping-ltd-blockpaving-driveways-haringey'>
      <img className='trustatrade1'src={trustatrade} alt='travel'/>
      </Link>
    </div>
    </div>
    
       
    )
}
export default HomePageInfo;
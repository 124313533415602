const RoofingPhotos=[
    { src: '/images/roofing/R1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R24.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R25.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R26.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R28.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R30.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R32.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R35.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R36.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R37.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R38.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R39.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R41.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R43.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R44.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R45.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R46.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R47.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R48.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R49.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R50.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R51.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R52.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R54.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R55.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R56.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R57.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R58.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R59.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R60.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R61.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R62.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R63.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R64.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R65.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R66.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R68.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R69.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R70.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R71.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R72.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R73.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R74.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R75.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R76.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R77.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R78.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R79.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R80.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R81.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R82.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R83.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R84.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R85.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R86.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R87.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R88.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R89.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R90.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R91.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R92.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R93.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R94.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R95.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R96.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R97.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R98.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R99.webp', alt: 'Cute Kitten 1' },
    { src: '/images/roofing/R100.webp', alt: 'Cute Kitten 1' },
];
export default RoofingPhotos;
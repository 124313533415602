const DrivewayPhotos =[

    { src: '/images/driveways/D1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D2.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D13.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D14.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D15.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D16.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D20.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D21.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D22.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D23.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D27.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D28.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D29.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D30.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D32.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D33.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D34.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/driveways/D36.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D37.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D38.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D39.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D40.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D41.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D42.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D43.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D44.webp', alt: 'Cute Kitten 1' },
    
    { src: '/images/driveways/D49.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D50.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D51.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D52.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D53.webp', alt: 'Cute Kitten 1' },
   
    { src: '/images/driveways/D55.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D56.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D57.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D58.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D59.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D60.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D61.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D62.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D63.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D64.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D65.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D66.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D67.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D69.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D73.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D74.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D78.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D79.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D80.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D81.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D82.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D83.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D84.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D85.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D86.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D87.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D88.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D89.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D90.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D91.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D92.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D93.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D95.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D96.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D97.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D98.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D99.webp', alt: 'Cute Kitten 1' },
    { src: '/images/driveways/D10.webp', alt: 'Cute Kitten 1' },
  
  ];
  export default DrivewayPhotos;

const feedbacks = [
    {
      name: "Sarah",
      jobTitle: "Valley Roof Replacement",
      feedback: "Argon and his team were excellent, explained everything clearly, showed photographs etc. The team also repaired the stonework above my front door, there was a huge gap between the house and the stone, which was something we hadn't noticed - so thankfully all now safe. They also finished by painting the front of the house too. Highly recommended.",
      rating: 5,
    },
    {
      name: "Deirdre",
      jobTitle: "Roof Renewal",
      feedback: "We had a leak in the house from the roof! We called the company and they started straight away, worked in all weathers, scaffold was up the next day and work started after that! The workers were great and the job completed to a very high standard! Talking us through every detail ! Would recommend them every time !",
      rating: 5,
    },
    {
      name: "Elliot",
      jobTitle: "New Driveway, garden excavation and new turf laid",
      feedback: "They were professional, efficient, and paid great attention to detail from start to finish. The driveway looks fantastic, with clean lines and a perfect finish, and the garden transformation is just as impressive. They kept the area tidy, communicated clearly throughout the process, and completed the work on time. I highly recommend them for anyone looking for quality work and a smooth experience!",
      rating: 5,
    },
    {
      name: "Neeraj",
      jobTitle: "Fixing and replacing a flat roof",
      feedback: "I had a great experience with ALD Construction who were always punctual, communicative and worked very hard. The standard of work was exceptional and I would very much recommend them to anyone looking for great professional service for roofing.",
      rating: 5,
    },
    {
      name: "Jasmin",
      jobTitle: "Replacing flashing on roof and pointing",
      feedback: "Agron was great - responsive, competitively priced and tidy. Was very happy with the work and would definitely recommend.",
      rating: 5,
    },
    {
      name: "John",
      jobTitle: "Driveway and Pathway Paving",
      feedback: "Agron and his team gave a very competitive quote, were very reliable, turned up when they said they would and worked consistently throughout the week until the job was finished. Other companies quoted us 2-3 weeks for the finished job, whereas Agron completed in a week and did a very good job, including carrying out extra work which we asked for. In all he took away the old side garden and tree stump, built a new paved driveway and paved walkway down the side alley to garden. Took down old front garden wall and rebuilt new wall. Paved the front garden under the bay window. Great job.",
      rating: 4.5,
    },
    {
      name: "Edward",
      jobTitle: "Tree Removal, Brickwork and Patio",
      feedback: "Excellent workmanship. We are extremely happy with the work carried out and would thoroughly recommend using ALD Construction",
      rating: 5,
    },
    {
      name: "Alex",
      jobTitle: "Paving our front walkway with Indian Sandstone",
      feedback: "Agron and his team did a beautiful job paving our front walkway from the street up to our door. The stone work looks incredible and really transformed our front garden. He removed all of the old elements from our patio, walkway and drive and made it into one smooth, high end design.",
      rating: 5,
    },
    {
      name: "James",
      jobTitle: "Driveway and Front Wall",
      feedback: "ALD Construction were very professional throughout with an extremely detailed and well laid out quote. The workforce were friendly and hard working and looked to get the job done to a very high standard.",
      rating: 5,
    },
    {
      name: "Dominic",
      jobTitle: "Patio Pavers",
      feedback: "Qouted and did the work very quickly. The friendly team made it all easy",
      rating: 4.5,
    },
    {
      name: "Maia",
      jobTitle: "Decking & Garden Fence",
      feedback: "Agron and team were great, were very efficient. Would recommend.",
      rating: 4,
    },
    {
      name: "Antony",
      jobTitle: "Repairing portions of the top roof",
      feedback: "Agron is an exemplary roofer who delivers exceptional results and outstanding customer service. His meticulous attention to detail, diligent work ethic, and transparent communication style make him a pleasure to work with. Agron's professionalism, expertise, and commitment to quality are evident in his work, and I would highly recommend him to anyone in need of roofing services. He consistently exceeded my expectations, demonstrating a clear understanding of what was involved in the project and providing helpful advice and guidance.",
      rating: 5,
    },
    {
      name: "Isabel",
      jobTitle: "Complex remedial damp works",
      feedback: "Its become so difficult to find competent tradespeople who are available but from Agrons first visit to the site he inspired confidence with his commitment to understanding the job (complex remedial damp works) and completing it to the highest standards. Hes also very friendly, reliable and good at communicating. His company is not the cheapest but you get what you pay for - Im sure Ill hire him again in the future.",
      rating: 5,
    },
    {
      name: "Stephen",
      jobTitle: "Patio, retaining wall and garden fences",
      feedback: "Argon and his team did an excellent job on this landscaping and patio project",
      rating: 4.3,
    },
    {
      name: "Will",
      jobTitle: "Flat roof installation",
      feedback: "Agron and his team were great and provided a high quality service and were very friendly. Would definitely recommend",
      rating: 4.8,
    },
    {
      name: "Mark",
      jobTitle: "Fixing a leak in roof",
      feedback: "Found a quick solution to the problem with our leak and carried out the work quickly and efficiently. Agron was great in customer service and keeping communication throughout the process",
      rating: 4,
    },
    {
      name: "Angelo",
      jobTitle: "Replace existing roof",
      feedback: "Agron and his team were fantastic. They replaced the entire roof(tiles, fascia, guttering) and reinforced the structure. They completed the work to high standards and kept me up to date with the progress. The place was left tidy. I would definetely recommend them.",
      rating: 5,
    },
    {
      name: "Lesley",
      jobTitle: "New roof",
      feedback: "Really good work!! Roof throughly done",
      rating: 3.8,
    },
    {
      name: "Thomas",
      jobTitle: "New brickwall, garden fence and concrete slab",
      feedback: "We would highly recommend Agron and his team. They have completed three separate projects for us - (1) replacing old fencing with new concrete posts and wooden fence panels, (2) pouring a new concrete slab and (3) building a new brick and stone wall at the front of our house. Each project was carried out on time and for the agreed price and we are really happy with the quality of the work. We will not hesitate to ask Agron to do future work for us.",
      rating: 5,
    },
    {
      name: "Richard",
      jobTitle: " Stop Damp. Replace guttering, repoint chimney and brickwork",
      feedback: "Very professional and trustworthy ",
      rating: 3.8,
    },
    {
      name: "Anthony B",
      jobTitle: " Part replacement of roof and pointing;guttering etc",
      feedback: "Good quality work coupled with friendly, reliable and flexible service. Definetly recommend this company. ",
      rating: 4,
    },
    {
      name: "Raiiv",
      jobTitle: " New paving and fencing",
      feedback: "These guys were excellent, effiecient and very easy to work with. Highly recommended",
      rating: 5,
    },
    {
      name: "Damien",
      jobTitle: "Replacing roof tiles, chimney pots and guttering",
      feedback: "Agron and his team from ALD Construction and Landscaping Ltd did two days work for us including removing and replacing roof tiles and a chimney pot and some guttering. They were extremely polite, friendly, punctual and clean. We were pleased with the work which was of a high quality and was delivered on time. Communication and billing was clear and we would recomend them to friends",
      rating: 5,
    },
    {
      name: "Rama",
      jobTitle: "Garden house interiors and paving outside alongwith fencing, guttering etc",
      feedback: "Agron and his team were simply fabulous. We were in a bad state when they took on our work; the previous builders who we had hired had abruptly stopped work and had disappeared. It was quite stressful. We needed someone to finish the job. Not only did Agron finish the job to a high standard, he made sure that everything was done perfectly. He's is very particular about finishing the job on time and when he's doing your job, you are his only priority - a trait we greatly appreciated. Though they are a tad expensive, we are very pleased with their work and would highly recommend them.",
      rating: 4,
    },
    {
      name: "Christos",
      jobTitle: "Roof Repairs",
      feedback: "Very professional! Always on time. We were suprised the job was completed on the time frame we were given. Highly recommended",
      rating: 5,
    },
    {
      name: "Julie",
      jobTitle: "Replace patio, complete path, build steps, fix shed, new fence",
      feedback: "Agron was very trustworthy, approachable and patient. From meeting him when he came over for the quote we were impressed with his passion and ideas. He also gave us numbers of other clients to speak with to see his work. He and his team were always on time, respectful of the property and made sure we were happy with the works as they progressed. They were very hard-working and did a great job on our patio, fence and driveway. We wouldn’t hesitate using ALD again and we are happy to recommend them! Thank you!",
      rating: 5,
    },
    {
      name: "Chloe",
      jobTitle: "Driveway and pavement repair",
      feedback: "ALD Construction and Landscaping Ltd has done a great job for us, we will 100% be recommending these guys. Honest, trustworthy and hardworking. The driveway came out better than we expected.",
      rating: 4.5,
    },
    {
      name: "Mark",
      jobTitle: "Driveway",
      feedback: "This was an excellent piece of work. I rang up the previous week for a quote, it came back well priced and detailed. Work commenced the following week and was completed ON TIME and ON BUDGET. The work was carried out to an excellent standard, very happy with it and so are the neighbours. I will be using them again now for any house improvements. Thank you so much!",
      rating: 5,
    },
    {
      name: "Dany",
      jobTitle: "New flooring, gravle at the back patio and underpin installation",
      feedback: "Brilliant fitting!",
      rating: 5,
    },
    {
      name: "Natalie",
      jobTitle: "Garden paving and fencing",
      feedback: "I highly recommend ALD Construction and Landscaping Ltd. They did a fantastic job in my garden. The team is very professional, punctual, diligent and friendly. I will hire them again for future jobs.",
      rating: 5,
    },
    {
      name: "David",
      jobTitle: "Fully replaced patio with new porcelain tile, laid new lawn and rendered garden wall",
      feedback: "Excellent overall experience from initial estimate, scheduling of work and removal/replacement of garden patio. Work was completed as scheduled, with a high level of diligence and quality. Pricing was completely transparent. Highly recommended for your landscaping project.",
      rating: 4.5,
    },
    {
      name: "B",
      jobTitle: "Front patio and back garden refurb",
      feedback: "ALD did a great job. Good value for money, friendly and professional team and the work was completed to a high standard. Would highly recommend and would definitely use them again!",
      rating: 4.5,
    },
    {
      name: "Paula",
      jobTitle: "Install a new driveway",
      feedback: "Agron and his team did a wonderful job installing a new driveway. He went out of his way to fit me in as I needed the work done ASAP. Very professional and courteous, very high standards of work. Neighbours have already commented on how great it looks. Would not hesitate to recommend! Very happy with the work carried out! Thanks Agron",
      rating: 5,
    },
    {
      name: "Clarissa",
      jobTitle: "Wall,drainage and soakaway instalment",
      feedback: "The team were great they managed to squeeze us into their busy schedule quite quickly. They were on time and got down to work with no issues. They did a great job and I love our new and improved driveway. I would hire them again to do more work if needed.",
      rating: 4.3,
    },
    {
      name: "Colin",
      jobTitle: "Patio seating area, pathway landscaping around pond area, garden and turfing",
      feedback: "Very happy with the quality and standard of work, from a very friendly and professional team. ",
      rating: 5,
    },
    {
      name: "Vicky",
      jobTitle: "Driveway and patio",
      feedback: "An excellent job by Agron and his team. The work was carried out effienciently, tidly and to an excellent standard. I couldn't be happier! ",
      rating: 5,
    },
    {
      name: "Mark M",
      jobTitle: "Replacing decking with sandstone patio",
      feedback: "Agron and the team were brilliant. The patio is finished to a very high standard and done in quick time as the team were very hard working. They were very respectful of our things when moving them and kept the house clean by putting down mats and hovering at the end of each day. Thanks a lot!",
      rating: 5,
    },
    {
      name: "Lm",
      jobTitle: "New patio",
      feedback: "Excellent, polite and hardworking",
      rating: 4.8,
    },
    {
      name: "Devan",
      jobTitle: "Porcelain garden tiles and grouted",
      feedback: "Argon and his team were great and very polite. Arrived promptly as planned, laid porcelain garden tiles quickly, neatly and took the time to make sure it was all done properly. Very happy with the work and the finish. I would use them again.",
      rating: 5,
    },
    {
      name: "Sage",
      jobTitle: "New brick retaining walls, new patio and some fencing",
      feedback: "Good quality work, shows up on time each day, keeps area pretty tidy (was very difficult in early stages as weather was terrible with snow etc). Am using for other jobs as happy with this work.",
      rating: 4.3,
    },
    {
      name: "James R",
      jobTitle: "New path and patio area in garden, and rebuilt front yard/wall",
      feedback: "Excellent - they were here early each morning and were always polite and friendly. They worked hard throughout the day and took all the pressure out of it from our side. They cleared up all the rubbish at the end and left the garden looking immaculate. I'd have no hesitation in recommending them, and will definitely be using them again for further work.",
      rating: 4.8,
    },
    {
      name: "Mike P",
      jobTitle: "Roofed pergola in the garden with flagstones",
      feedback: "Very good job. Agron's advice and ideas were very helpful in getting to a design which met our wishes. His team were hard working, friendly and professional - got the work done efficiently to a high standard. Would (& indeed have) employ them again.",
      rating: 4.8,
    },
    {
      name: "Jacqueline",
      jobTitle: "Front and back garden dug up and paved",
      feedback: "Job well done. Argon was very punctual, professional, affordable and friendly. My front garden looks very nice, I wish I could show you a picture. I would gladly recommend him to anybody.",
      rating: 4.3,
    },
    {
      name: "Kiera",
      jobTitle: "Re-laying a leaking flat roof and re-tiling bathroom splashback",
      feedback: "Did a great job on the roof and bathroom and went above and beyond to fix a couple of extra bits as part of the job. Really quick response and the work was finished quickly and efficiently. Thanks",
      rating: 5,
    },
    {
      name: "Kawsar",
      jobTitle: "Roof Repairs and Installation of New Flat Roof",
      feedback: "Very trustworthy and understanding. Was able to attend quickly and give a quote and then carry out works fast. Just what we needed. Would thoroughly recommend",
      rating: 5,
    },
    {
      name: "Amelia",
      jobTitle: "New Concrete Base, Painting & Decorating",
      feedback: "Very happy with the work carried, they communicated very well with me, I would definitely recommend them ",
      rating: 5,
    },
    {
      name: "Tony M",
      jobTitle: "Replace and repair guttering and replace a few roof tiles ",
      feedback: "Work completed on time. Very happy with the work they done for me. Will use again.",
      rating: 5,
    },
    {
      name: "Jesse",
      jobTitle: "Storage made under the stairs ",
      feedback: "We would be happy to recommend this firm. They work hard and always tidy up at the end of the day. We are happy with the work they did and would use them again.",
      rating: 4.8,
    },
    {
      name: "Jeff H",
      jobTitle: "Front of house painted ",
      feedback: "Very happy with whole experience",
      rating: 4.5,
    },
    {
      name: "Khagram",
      jobTitle: "Patio paving, laying artificial grass, resin bond for drive, new fence, and garage repair ",
      feedback: "Very good job done. Friendly and polite, Punctual, and tidy. Would recommend them to friends and family",
      rating: 4.8,
    },
    {
      name: "Laura",
      jobTitle: "New felt roof and decking",
      feedback: "Agron and his team were very professional and i was very pleased with the work. Would highly recommend them and wil definetely use them again in the future",
      rating: 5,
    },
    {
      name: "Penny",
      jobTitle: "New pathway and paintwork",
      feedback: "Agron and his team were very professional. My mother is delighted with her new path. I would highly recommend them",
      rating: 5,
    },
    {
      name: "Andrea A",
      jobTitle: "New patio",
      feedback: "Very happy with the finished result and would happily use this company again. Agron and his team arrived early and worked diligently. Work was completed to a high standard and patio was left spotless. Would highly recommend.",
      rating: 5,
    },
    {
      name: "George",
      jobTitle: "Replacement of back fenct and picket fence at the front property",
      feedback: "The work was completed on time and in a professional manner. We were listened to and the work was completed according to our requests. It was an extremely hot day replacing the back fence on a south facing garden but it was accomplished successfully.",
      rating: 4.8,
    },
    {
      name: "Carol",
      jobTitle: "New Driveway",
      feedback: "Highly recommend. Very high standards of work",
      rating: 5,
    },
    {
      name: "Colin M",
      jobTitle: "Relay shared driveway with resin compound",
      feedback: "Excellent all round service - professional, helpful and good team of workers. Nothing was to much trouble",
      rating: 4.8,
    },
    {
      name: "Alex R",
      jobTitle: "New driveway, patio extension, patio lying and fencing",
      feedback: "The builders were very trustworthy, honest and hardworking. I was very pleased. The standard of work was very good and they worked hard. Even over the weekend",
      rating: 4.8,
    },
    {
      name: "Bipin",
      jobTitle: "New fence and paving",
      feedback: "New fence paving pathway",
      rating: 4.8,
    },
    {
      name: "Micheal N",
      jobTitle: "Paving and brickwork and gate",
      feedback: "Good communication, good result",
      rating: 4.8,
    },
    {
      name: "Tobutt",
      jobTitle: "Garden Landscaping, Turf, Resin Bond For Drive",
      feedback: "Good service, friendly and reliable",
      rating: 4.5,
    },
    
  ];
  export default feedbacks;
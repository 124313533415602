const BathroomPhotos =[

    { src: '/images/bathroom/B1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B2.webp', alt: 'Cute Kitten 1' },
    // { src: '/images/bathroom/B3.jpg', alt: 'Cute Kitten 1' },
    // { src: '/images/bathroom/B4.jpg', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B13.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B14.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B15.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B16.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B20.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B21.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B22.webp', alt: 'Cute Kitten 1' },
    // { src: '/images/bathroom/B23.jpg', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B24.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B25.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B26.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B27.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B28.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B29.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B30.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B31.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B32.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B33.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B34.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B35.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B36.webp', alt: 'Cute Kitten 1' },
    { src: '/images/bathroom/B37.webp', alt: 'Cute Kitten 1' },
   
  ];
  export default BathroomPhotos;
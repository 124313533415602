const VictorianPhotos =[
    { src: '/images/victorian/V1.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V2.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V3.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V4.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V5.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V6.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V7.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V8.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V9.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V10.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V11.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V12.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V13.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V15.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V16.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V17.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V18.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V19.webp', alt: 'Cute Kitten 1' },
    { src: '/images/victorian/V20.webp', alt: 'Cute Kitten 1' },
];
export default VictorianPhotos; 
import React from 'react';
import '../../src/App.css';
import Cards from '../components/Cards';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import HomePageInfo from '../components/HomePageInfo';
import CustomerFeedback from '../components/CustomerFeedback';
function Home() {
  return (
    <>
    
      <HeroSection />
      <HomePageInfo/>
      
      <Cards />
    <CustomerFeedback/>
      <Footer />
    </>
  );
}

export default Home;

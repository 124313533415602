import React from 'react';
import './GalleryCards.css';
import CardItem from './CardItem';

function GalleryCards (){
    return(
        <div className='cards__container1'>
          <div className='albumtitle'>
            <h1 >Albums</h1>
            <p>
              Below are the Albums which contains the pictures of various projects that have been taken. 
            </p>
          </div>
          
        <div className='cards__wrapper1'>
          <ul className='cards__items1'>
           
            <CardItem
              src='images/driveways/D36.webp'
              text='Driveways '
              
              path='/gallery/driveways'
            />
             <CardItem
              src='images/patio/P4.webp'
              text='Patio'
              
              path='/gallery/patio'
            />
              
          </ul>
          <ul className='cards__items1'>
           <CardItem
              src='images/fencing/F2.webp'
              text='Fencing'
              
              path='/gallery/fencing'
            />
             
            <CardItem
              src='images/roofing/R73.webp'
              text='Roofing '
              
              path='/gallery/roofing'
            />
            
           
          </ul>
          <ul className='cards__items1'>
             <CardItem
              src='images/brickwork/BR3.webp'
              text='Brickwork'
              
              path='/gallery/brickwork'
            /> 
            <CardItem
              src='images/victorian/V4.webp'
              text='Victorian Tiling'
              
              path='/gallery/victoriantiles'
            /> 
            <CardItem
              src='images/bathroom/B6.webp'
              text='Bathroom'
              
              path='/gallery/bathroom'
            /> 
           
          </ul>
        </div>
      </div>
   
    )
} 
export default GalleryCards;